import { Box, Divider, FormControlLabel, Grid, List, ListItem, ListItemButton, Switch, Tab, Tabs, Typography } from "@mui/material";
import BaseLayout from "../../../../layout/BaseLayout.component";
import RightMenu from "../../../../layout/RightMenu.component";
import { useCallback, useRef, useState } from "react";
import { CreateDefaultMostRecentPublishedScoresRequest, MostRecentPublishedScoresRequest } from "../../../../models/MostRecentPublishedScoresRequest.model";
import TypeAheadMulti from "../../../../components/formControls/TypeAheadMultiBase.component";
import NumericDropDownModel from "../../../../models/NumericDropDown.model";
import { GetMaturityModelSearch } from "../../../../services/MaturityModel.service";
import { GetRegionTypeAheadSearch } from "../../../../services/Region.service";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import { GetCountryTypeAheadSearch } from "../../../../services/Country.service";
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { DownloadMostRecentPublishedScoresExport } from "./LatestPublishedScoresOverview.service";
import Loading from "../../../../components/loading/Loading.component";
import moment from "moment";
import { toPng } from "html-to-image";
import { userHasPermission } from "../../../../services/User.service";
import TabPanel, { a11yProps } from "../../../../components/tabs/TabPanel.component";
import AssessmentsCompletedByRegion from "./AssessmentsCompletedByRegion.component";
import AssessmentsCompletedByCountry from "./AssessmentsCompletedByCountry.component";
import MaturityMOdelOverview from "./MaturityModelOverview.component";

const LatestPublishedScoresOverview = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [queryOptions, setQueryOptions] = useState<MostRecentPublishedScoresRequest>(CreateDefaultMostRecentPublishedScoresRequest());
    const {appUser} = useApiAuthorization();
    // const [dataVizData, setDataVizData] = useState<LatestPublishedScoresOverviewResponse>(CreateDefaultLatestPublishedScoresOverviewDataVisualization());
    const visualizationRef = useRef<HTMLDivElement>(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [showAxisCounts, setShowAxisCounts] = useState<boolean>(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        // event.type can be equal to focus with selectionFollowsFocus.
        setCurrentTab(newValue);
    };
    
    
    
    const downloadVisualization = useCallback(() => {
        if (visualizationRef.current === null) {
            return
        }

        toPng(visualizationRef.current, { cacheBust: true, style: {
            padding: '15px'
        }}).then((dataUrl) => {
            const link = document.createElement('a')
            link.download = 'LatestPublishedScoresOverview.png'
            link.href = dataUrl
            link.click()
        }).catch((err) => {
            console.log(err)
        });
    }, [visualizationRef]);
    
    const downloadExport = useCallback(() => {
        setIsLoading(true);
        DownloadMostRecentPublishedScoresExport(queryOptions, appUser?.token).then(response => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response.blob();
        }).then(blob => {
            if(blob != null)
            {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "PublishedScoresExport.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        }).finally(() => setIsLoading(false));
    }, [appUser?.token, queryOptions]);

    const childMenu = (
        <RightMenu>
            <List component="nav" sx={{overflowX: "auto"}}>
                <ListItem>
                    <Typography variant="h6">Action Items</Typography>
                </ListItem>
                <ListItemButton disabled={isLoading} onClick={() => setQueryOptions(CreateDefaultMostRecentPublishedScoresRequest())} key="reset">
                    Reset Filters
                </ListItemButton>
                {userHasPermission("InsightsLatestPublishedScoresOverview_Detail_Export", appUser) && 
                <ListItemButton onClick={downloadExport} disabled={isLoading}>
                    Download Export
                </ListItemButton>
                }
                <ListItemButton onClick={downloadVisualization} disabled={isLoading}>
                    Download Visualization
                </ListItemButton>
                <Divider/>
                <ListItem>
                    <Typography variant="h6">Settings</Typography>
                </ListItem>
                <ListItem>
                    <FormControlLabel 
                        control={
                            <Switch
                                disabled={isLoading}
                                checked={showAxisCounts === true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setShowAxisCounts(checked)}
                            />
                        }
                        label="Display Axis Counts"
                    />
                </ListItem>
                <Divider/>
                <ListItem>
                    <Typography variant="h6">Filters</Typography>
                </ListItem>
                <ListItem>
                    <TypeAheadMulti
                        value={queryOptions.modelIDs}
                        disabled={isLoading}
                        onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => setQueryOptions((prevState) => { return {...prevState, modelIDs: newValue} })}
                        label="Maturity Model"
                        searchCallback={(searchTerm?:string) => GetMaturityModelSearch(searchTerm, [7,8,9,10,11,13])}
                    />
                </ListItem>
                <ListItem>
                    <TypeAheadMulti
                        value={queryOptions.regionIDs}
                        disabled={isLoading}
                        label="Regions"
                        onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => setQueryOptions((prevState) => { return {...prevState, regionIDs: newValue} })}
                        searchCallback={(searchTerm?:string) => GetRegionTypeAheadSearch(searchTerm, [2,4,5,7,1001,1004])}
                    />
                </ListItem>
                <ListItem>
                    <TypeAheadMulti
                        label="Countries"
                        disabled={isLoading}
                        value={queryOptions.countryIDs}
                        onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => {
                            setQueryOptions((prevState) => { return {...prevState, countryIDs: newValue} })}
                        }
                        searchCallback={(searchTerm?: string) => GetCountryTypeAheadSearch('', searchTerm, queryOptions.regionIDs)}
                    />
                </ListItem>
                <ListItem>
                    <DateRangePicker
                        onAccept={(value: [moment.Moment|null, moment.Moment|null]) => setQueryOptions({...queryOptions, validationDateRange: value})}
                        value={queryOptions.validationDateRange}
                        label="Publication Date"
                        slots={{ field: SingleInputDateRangeField }}
                        slotProps={{textField: {variant: 'standard', fullWidth: true}}}
                        disabled={isLoading}
                        timezone="UTC"
                    />
                </ListItem>
                <ListItem>
                    <DateRangePicker
                        onAccept={(value: [moment.Moment|null, moment.Moment|null]) => setQueryOptions({...queryOptions, validationExpirationDateRange: value})}
                        value={queryOptions.validationExpirationDateRange}
                        label="Scoring Expiration Date"
                        slots={{ field: SingleInputDateRangeField }}
                        slotProps={{textField: {variant: 'standard', fullWidth: true}}}
                        disabled={isLoading}
                        timezone="UTC"
                    />
                </ListItem>
                <ListItem>
                    <FormControlLabel 
                        control={
                            <Switch
                                disabled={isLoading}
                                checked={queryOptions.includeExpiredResults === true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setQueryOptions({...queryOptions, includeExpiredResults: checked})}
                            />
                        }
                        label="Include Expired Scorings"
                    />
                </ListItem>
            </List>
        </RightMenu>
    );
    return (
        <BaseLayout childMenu={childMenu}>
            {isLoading && <Loading/>}
            <Grid container spacing={3} sx={{pb:10, backgroundColor: "#ffffff"}}>
    
                <Grid item xs={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width:"100%" }}>
                        <Tabs
                            value={currentTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab label='Published Scores Overview' {...a11yProps(0)} />
                            <Tab label='Published Scores By Region' {...a11yProps(1)} />
                            <Tab disabled={!queryOptions.modelID} label='Published Scores By Country' {...a11yProps(2)} />

                        </Tabs>
                    </Box>
                </Grid>
                <Grid container ref={visualizationRef}>
                    <TabPanel value={currentTab} index={0}>
                        <MaturityMOdelOverview 
                            queryOptions={queryOptions} 
                            onLoad={(isLoading: boolean) => setIsLoading(isLoading)}
                            axisLable={(item) => {
                                return showAxisCounts ? item.value?.toFixed(0).toLocaleString() : undefined;
                            }}
                            updateQueryOptions={(options) => setQueryOptions(options)}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <AssessmentsCompletedByRegion 
                            queryOptions={queryOptions} 
                            onLoad={(isLoading: boolean) => setIsLoading(isLoading)}
                            axisLable={(item) => {
                                return showAxisCounts ? item.value?.toFixed(0).toLocaleString() : undefined;
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                        <AssessmentsCompletedByCountry 
                            queryOptions={queryOptions} 
                            onLoad={(isLoading: boolean) => setIsLoading(isLoading)}
                            axisLable={(item) => {
                                return showAxisCounts ? item.value?.toFixed(0).toLocaleString() : undefined;
                            }}
                        />
                    </TabPanel>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="caption" sx={{fontSize: 10}}>
                            *The counts shown are reflective of currently published and validated scores during the selected date range. If an organization participated multiple times, only the latest published score will be taken into account, not reflecting any previous publications.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="caption" sx={{fontSize: 10}}>
                            ©{moment().year()} HIMSS. All rights reserved. This document and any of the information contained herein may not be reproduced or reprinted under any circumstance without the expressed written consent of HIMSS.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" sx={{fontSize: 10}}>
                        **Expiration dates are per default set to 3 years after Publication. Extensions can be granted for Stage 6/7 validations in exceptional cases.
                        </Typography>
                    </Grid>
                </Grid>
                
            </Grid>
        </BaseLayout>
    );
}

export default LatestPublishedScoresOverview;