import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import { isEmpty } from "lodash";
import { EmptyTableTable } from "./EmptyTable.component";


const renderCell = (row: any, rowName: string) => {
    if (isNaN(row[rowName]) !== true) {
        return row[rowName].toLocaleString() + "%";
    }

    return row[rowName];
}

export const DHIFocusAreaTable = (item: any) => {
    
    if (isEmpty(item.data)) {
        return (
            <EmptyTableTable />
        )
    }
    
    return (
        <TableWrapper>
            <TableContainer>
                <StyledTable sx={{mt:4, mb: 4}}> 
                    <TableHead>
                        <TableRow sx={{backgroundColor: "#1e22aa"}}>
                            {item.data.headerData.map((row:any, index: number) => (
                                <TableCell key={`focusAreaHeader-${index}`} align="center" sx={{color: "#ffffff", fontWeight: "700"}}>{row.text}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.data.rowData.map((row: any, index: number) => (
                            <TableRow
                                key={`focusAreaRow-${index}`}
                            >
                                {row.hasOwnProperty('col1') &&
                                <TableCell align="center">{renderCell(row, "col1")}</TableCell>                            
                                }
                                {row.hasOwnProperty('col2') &&
                                <TableCell align="center">{renderCell(row, "col2")}</TableCell>                            
                                }
                                {row.hasOwnProperty('col3') &&
                                <TableCell align="center">{renderCell(row, "col3")}</TableCell>                            
                                }
                                {row.hasOwnProperty('col4') &&
                                <TableCell align="center">{renderCell(row, "col4")}</TableCell>                            
                                }
                                {row.hasOwnProperty('col5') &&
                                <TableCell align="center">{renderCell(row, "col5")}</TableCell>                            
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};