import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import { isEmpty } from "lodash";
import { EmptyTableTable } from "./EmptyTable.component";

const renderCell = (row: any, rowName: string) => {
    if (row.id !== 1 && row[rowName] !== "N/A") {
        return row[rowName].toLocaleString() + "%";
    }

    return row[rowName];
}
    
const applyDynamicClass = (row: any) => {
    if (row.id < 3) {
        return "bg-blue";
    }

    if (row.hgFailed === 1) {
        return "bg-red";
    }

    if (row.col2 > 69.5) {
        return "bg-green";
    }

    if (row.col2 === "N/A") {
        return "" as string;
    }

    return "bg-yellow" as string;
}

export const PatientEngagementTable = (item: any) => {

    if (isEmpty(item.data)) {
        return (
            <EmptyTableTable />
        )
    }

    return (
        <TableWrapper>
            <TableContainer>
                <StyledTable>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                        {item.data.rowData.map((row: any, index: number) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell align="center">{row.col1}</TableCell>
                                <TableCell align="center" className={applyDynamicClass(row)}>{renderCell(row, "col2")}</TableCell>
                                <TableCell align="center">{row.col3}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};
