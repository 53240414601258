import { Box } from "@mui/material";
import { preStyledImages } from "./PreStying.component";
import { isEmpty } from "lodash";

export const ImageWithPreStyling = (item: any, index: number) => {
        
    if (isEmpty(item.data)) {
        return (<></>)
    }

    let styleOverride = item.data.text[0].style
        ? JSON.parse(item.data.text[0].style)
        : [];
    return (
        <Box
            key={`Image-` + index}
            component="img"
            sx={{
                width: 350,
                maxWidth: "100%",
                ...(preStyledImages[item.type] && {
                    ...preStyledImages[item.type],
                }),
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
            alt=""
            src={item.data.text[0].url}
            className="aar-image"
        />
    );
}
