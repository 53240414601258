import { TableContainer, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import { isEmpty } from "lodash";
import { EmptyTableTable } from "./EmptyTable.component";

const applyClassByFlag = (params: any) => {
    if(params === "4") {
        debugger;
        return "bg-lightGray"
    }
    
    if (params === "3") {
        return "bg-red";
    }

    if (params === "2") {
        return "bg-yellow";
    }

    if (params === "1") {
        return "bg-green";
    }

    return "" as string;
};

export const DiamEnterpriseCriteria = (item: any) => {
    if (isEmpty(item.data)) {
        return (
            <EmptyTableTable />
        )
    }
    return (
        <TableWrapper>
            <TableContainer sx={{marginTop: "25px"}}>
                <StyledTable sx={{mb:4}}>
                    <TableHead sx={{backgroundColor: "#1e22aa", padding: "7px"}}>
                        <TableRow>
                            <TableCell
                                align="center"
                                sx={{ color: "#ffffff", fontWeight: 700, minWidth: "50px"}}
                            >
                                Stage
                            </TableCell>
                            <TableCell align="center" sx={{color: "#ffffff", fontWeight: 700}}>Mandatory</TableCell>
                            <TableCell align="center" sx={{color: "#ffffff", fontWeight: 700}}>Criteria</TableCell>
                            <TableCell align="center" sx={{color: "#ffffff", fontWeight: 700}}>Criteria Met</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.data.rowData.map((row: any, index:number) => (
                            <TableRow
                                key={index}
                                sx={row.stage === null ? { backgroundColor: "#55c1e9"}: {}}
                            >
                                <TableCell align="center">{row.stage}</TableCell>
                                <TableCell align="center">
                                    {row.stage === null ? '' : row.col1 === true ? "Yes" : "No"}
                                </TableCell>
                                {row.hasOwnProperty("col2") &&
                                <TableCell align="left" sx={row.stage === null ? { color: "#fff", fontWeight: 700}: {}}>
                                    {row.stage === null ? `Focus Area: ${row.col2}`:row.col2}
                                </TableCell>                            
                                }
                                {row.hasOwnProperty("col3") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col3)}></TableCell>                            
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};