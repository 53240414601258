import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import { isEmpty } from "lodash";
import { EmptyTableTable } from "./EmptyTable.component";

const renderCell = (row: any, rowName: string, index:number) => {
    if(row[rowName] === null){
        return 'N/A';
    }

    if (index !== 0 && !isNaN(row[rowName]) && row[rowName] !== undefined && row[rowName] !== null) {
        return row[rowName].toLocaleString() + "%";
    }
    return row[rowName];
}
    
const applyDynamicClass = (row: any,rowName: string, index: number) => {
    if (index === 0) {
        return "bg-blue";
    }

    if (row[rowName] > 69.5) {
        return "bg-green";
    }

    if (isNaN(row[rowName]) || index === 9 || row[rowName] === null) {
        return "" as string;
    }

    return "bg-yellow" as string;
}

export const DiamServiceAreaAchievement = (item: any) => { 

    if (isEmpty(item.data)) {
        return (
            <EmptyTableTable />
        )
    }

    return (    
        <TableWrapper>
            <TableContainer>
                <StyledTable sx={{minWidth: "600px"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"></TableCell>
                            {item.data.headerData.map((row: any, index: number) => 
                                (<TableCell align="center" key={`diamsvcHeader-${index}`}>{row.col1}</TableCell>)
                            )} 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.data.rowData.map((row: any, index:number) => (
                            <TableRow
                                key={`diamOveralllScoreRow-${index}`}
                            >
                                <TableCell align="center">{index > 1 && index < 9 ? `Stage ${row.stage}` : row.stage}</TableCell>
                                <TableCell align="center" className={applyDynamicClass(row, "col2",index)}>{renderCell(row, "col2",index)}</TableCell>
                                {item.data.rowData[0].col3 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col3",index)}>{renderCell(row, "col3",index)}</TableCell>}
                                {item.data.rowData[0].col4 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col4",index)}>{renderCell(row, "col4",index)}</TableCell>}
                                {item.data.rowData[0].col5 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col5",index)}>{renderCell(row, "col5",index)}</TableCell>}
                                {item.data.rowData[0].col6 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col6",index)}>{renderCell(row, "col6",index)}</TableCell>}
                                {item.data.rowData[0].col7 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col7",index)}>{renderCell(row, "col7",index)}</TableCell>}
                                {item.data.rowData[0].col8 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col8",index)}>{renderCell(row, "col8",index)}</TableCell>}
                                {item.data.rowData[0].col9 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col9",index)}>{renderCell(row, "col9",index)}</TableCell>}
                                {item.data.rowData[0].col10 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col10",index)}>{renderCell(row, "col10",index)}</TableCell>}
                                {item.data.rowData[0].col11 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col11",index)}>{renderCell(row, "col11",index)}</TableCell>}
                                {item.data.rowData[0].col12 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col12",index)}>{renderCell(row, "col12",index)}</TableCell>}
                                {item.data.rowData[0].col13 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col13",index)}>{renderCell(row, "col13",index)}</TableCell>}
                                {item.data.rowData[0].col14 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col14",index)}>{renderCell(row, "col14",index)}</TableCell>}
                                {item.data.rowData[0].col15 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col15",index)}>{renderCell(row, "col15",index)}</TableCell>}
                                {item.data.rowData[0].col16 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col16",index)}>{renderCell(row, "col16",index)}</TableCell>}
                                {item.data.rowData[0].col17 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col17",index)}>{renderCell(row, "col17",index)}</TableCell>}
                                {item.data.rowData[0].col18 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col18",index)}>{renderCell(row, "col18",index)}</TableCell>}
                                {item.data.rowData[0].col19 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col19",index)}>{renderCell(row, "col19",index)}</TableCell>}
                                {item.data.rowData[0].col20 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col20",index)}>{renderCell(row, "col20",index)}</TableCell>}
                                {item.data.rowData[0].col21 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col21",index)}>{renderCell(row, "col21",index)}</TableCell>}
                                {item.data.rowData[0].col22 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col22",index)}>{renderCell(row, "col22",index)}</TableCell>}
                                {item.data.rowData[0].col23 !== null &&
                                <TableCell align="center" className={applyDynamicClass(row, "col23",index)}>{renderCell(row, "col23",index)}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};
