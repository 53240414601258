
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControlLabel, Grid, List, ListItem, ListItemButton, Switch, TextField, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import Loading from "../../components/loading/Loading.component";
import GoBack from "../../components/rightMenuControls/GoBack.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { CreateDefaultUserDetailModel, UserDetailCheckModel, UserDetailModel } from "./UserAccount.models";
import { CheckUser, FileUser, GetUserAccount, GetUserProfile, IsPasswordValid, SetUserPassword } from "./UserAccountDetail.service";
import UserAccountResetPasswordDialog from "./UserAccountResetPasswordDialog.component";
import UserAccountSetPasswordDialog from "./UserAccountSetPasswordDialog.component";
import { GetRoles } from "../Roles/UserRoleList.service";
import { useIsDirtyPrompt } from "../../components/formComponents/useIsDirtyPrompt.component";
import { CreateDefaultAssessmentSearchRequest } from "../Assessments/Assessment.models";
import { userHasPermission } from "../../services/User.service";
import { IsEmailValid } from "../../services/InputValidation.service";


function UserAccountDetail(){
    const {appUser} = useApiAuthorization();
    const { userID } = useParams();
    const userIDNumber = parseInt(userID!); 
    const [userAccount, setUserAccount] = React.useState<UserDetailModel>(CreateDefaultUserDetailModel());
    const initialUserAccount = React.useRef<UserDetailModel|null>(userIDNumber === 0 ? userAccount : null);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [errorDialogOpen, setErrorDialogOpen] = React.useState<boolean>(false);
    const [userCreateErrorDialogOpen, setUserCreateErrorDialogOpen] = React.useState<boolean>(false);
    const [userCreateErrorDialogResetPassword, setUserCreateErrorDialogResetPassword] = React.useState<boolean>(false);
    const [userCreateErrorDialogText, setUserCreateErrorDialogText] = React.useState<string>('');
    const [showPasswordValidationMessage, setShowPasswordValidationMessage] = React.useState<boolean>(false);
    const [showUserNameValidationMessage, setShowUserNameValidationMessage] = React.useState<boolean>(false);
    const [showValidationText, setShowValidationText] = React.useState<boolean>(false);
    const [showSetPassWordDialog, setShowSetPassWordDialog] = React.useState<boolean>(false);
    const [showResetPassWordDialog, setShowResetPassWordDialog] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const isDirty = useIsDirtyPrompt(initialUserAccount.current, userAccount);

    React.useEffect(() => {
        if(userIDNumber !== 0 && !Number.isNaN(userIDNumber)){
            setLoading(true);
            GetUserAccount(userIDNumber, appUser!.token)
                .then((response) => response.json())
                .then((data) => {
                    setLoading(false);
                    setUserAccount(data);
                    initialUserAccount.current = data;
                });
        }else if(Number.isNaN(userIDNumber) && appUser?.token){
            setLoading(true);
            GetUserProfile(appUser!.token)
                .then((response) => response.json())
                .then((data) => {
                    setLoading(false);
                    setUserAccount(data);
                    initialUserAccount.current = data;
                });
        }
    }, [appUser, userIDNumber]);

    React.useEffect(() => {
        setShowPasswordValidationMessage((!!userAccount.password || showValidationText) && !IsPasswordValid(userAccount.password));
    }, [userAccount.password, showValidationText]);

    React.useEffect(() => {
        setShowUserNameValidationMessage((!!userAccount.email || showValidationText) && !IsEmailValid(userAccount.email));
    }, [userAccount.email, showValidationText])

    const handleInternalFlagChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setUserAccount({...userAccount, isInternalFlag: checked});
    };
    
    const loadUserAssessments = async () => {
        navigate("/assessments/", { relative: "path", state: { componentState: { ...CreateDefaultAssessmentSearchRequest(), delegateUserIDs: [{ label: userAccount.email, value: userAccount.userID }] }}});
    };

    const updateUser = () => {
        const userIsNew = userIDNumber === 0 ? true : false;
        let isPasswordValid = userIsNew ? IsPasswordValid(userAccount.password) : true;
        let isUserNameValid = IsEmailValid(userAccount.email);

        if(!isPasswordValid || !isUserNameValid){
            setShowValidationText(true);
            return undefined;
        }

        setSaving(true);

        CheckUser(appUser!.token!, userAccount)
            .then((response) => {
                if(response.status === 400){
                    setErrorDialogOpen(true);
                    return undefined;
                }

                return response.json();
            })
            .then((data:UserDetailCheckModel) => {
                if (userIsNew && data.userExistsInAuth0 && data.userExistsInHADb) {
                    setUserCreateErrorDialogText(`The user account already exists: ${ userAccount.email }`);
                    setUserCreateErrorDialogResetPassword(false);
                    setUserCreateErrorDialogOpen(true);

                    return undefined;
                } else if (userIsNew && data.userExistsInAuth0 && !data.userExistsInHADb) {
                    setUserCreateErrorDialogText('The user account already exists in Auth0 and must be created for this application. Would you like to reset the password to what is entered on this page?');
                    setUserCreateErrorDialogResetPassword(true);
                    setUserCreateErrorDialogOpen(true);

                    return undefined;
                } else {
                    if(userAccount.userID === 0){
                        initialUserAccount.current = userAccount;
                    }
                    FileUser(appUser!.token!, userAccount)
                    .then((response) => {
                        if(response.status === 400){
                            setErrorDialogOpen(true);
                            return undefined;
                        }

                        return response.json();
                    })
                    .then((data:UserDetailModel) => {
                        if(userAccount.userID === 0){
                            navigate(`/users/${data.userID}`);
                        }
                        initialUserAccount.current = data;
                        setUserAccount(data);
                    })
                    .catch(() => setErrorDialogOpen(true));
                }
            })
            .catch(() => setErrorDialogOpen(true))
            .finally(() => {
                setSaving(false);
                setShowValidationText(false);
            }
        );
    };

    const createExistingAuth0User = (resetPassword: boolean) => {
        setSaving(true);

        //TODO: Make dialog go away
        if(userAccount.userID === 0){
            initialUserAccount.current = userAccount;
        }
        FileUser(appUser!.token!, userAccount)
            .then((response) => {
                if(response.status === 400) {
                    setErrorDialogOpen(true);
                    return undefined;
                }

                return response.json();
            })
            .then((data:UserDetailModel) => {
                if(userAccount.userID === 0) {
                    if (resetPassword) {
                        initialUserAccount.current = userAccount;
                        SetUserPassword(data.auth0ID!, appUser!.token, userAccount.password!)
                            .then(() => {
                                navigate(`/users/${data.userID}`);
                            }
                        );
                    } else {
                        navigate(`/users/${data.userID}`);
                    }
                }
                initialUserAccount.current = data;
                setUserAccount(data);
            })
            .catch(() => setErrorDialogOpen(true))
            .finally(() => {
                setSaving(false);
                setShowValidationText(false);
            }
        );
    };

    const childMenuContent = (
        <RightMenu>
            <List component="nav">
                <ListItem>
                    <Typography variant="h6">Action Items</Typography>
                </ListItem>
                <GoBack/>
                { userHasPermission("UserAccount_Password_Edit", appUser) &&
                <ListItemButton onClick={() => setShowResetPassWordDialog(true)}>Create Reset Password Link</ListItemButton>
                }
                { userHasPermission("UserAccount_Password_Edit", appUser) &&
                <ListItemButton onClick={() => setShowSetPassWordDialog(true)}>Set Password</ListItemButton>
                }
                <Divider/>
                <ListItem>
                    <Typography variant="h6">Links</Typography>
                </ListItem>
                { userHasPermission("Survey_List_View", appUser) &&
                <ListItemButton onClick={ loadUserAssessments }>Assessments</ListItemButton>
                }
            </List>
        </RightMenu>);

    return (
        <BaseLayout childMenu={(userHasPermission("UserAccount_Detail_Edit", appUser) || userHasPermission("Survey_List_View", appUser) ? childMenuContent : false) }>
            {(saving || loading) && <Loading />}
            {userHasPermission("UserAccount_Detail_Edit", appUser) &&
            <Box display="flex" justifyContent="flex-end">
                <Fab size="medium" color="primary" aria-label="save" variant="extended" onClick={updateUser} disabled={!isDirty}>
                    {userAccount.userID === 0 ? 'Create' : 'Save'}
                </Fab>
            </Box>
            }
            <Typography variant="h6" gutterBottom>
                User Information
            </Typography>
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="userId"
                            label="User ID"
                            fullWidth
                            variant="standard"
                            value={userAccount.userID}
                            disabled
                        />
                    </Grid> 
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="auth0ID"
                            label="Auth0 ID"
                            fullWidth
                            variant="standard"
                            value={userAccount.auth0ID ?? ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            name="userName"
                            label="Username/Email"
                            fullWidth
                            variant="standard"
                            value={userAccount.email ?? ""}
                            disabled={!userHasPermission("UserAccount_Detail_Edit", appUser) || userAccount.userID !== 0}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setUserAccount({...userAccount, email: event.target.value });
                            }}
                            helperText={showUserNameValidationMessage ? "Must be a valid email address." : undefined}
                            error={showUserNameValidationMessage}
                        />
                    </Grid>
                    { 
                        userAccount.userID === 0 && 
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="password"
                                label="Password"
                                fullWidth
                                variant="standard"
                                required
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    setUserAccount({...userAccount, password: event.target.value});
                                }}
                                helperText={showPasswordValidationMessage ? "Must be at least 8 characters long and contain at least three of the of the following character types: lower case (a-z), upper case (A-Z), numbers (0-9), special characters (e.g. !@#$%^&*)" : undefined}
                                error={showPasswordValidationMessage}
                            />
                        </Grid>
                    } 
                    { userAccount.userID !== 0 && <Grid item xs={12} sm={6}/> }                    
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="firstName"
                            label="First Name"
                            fullWidth
                            variant="standard"
                            value={userAccount.firstName ?? ""}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setUserAccount({...userAccount, firstName: event.target.value });
                            }}
                            disabled={!userHasPermission("UserAccount_Detail_Edit", appUser) }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="lastName"
                            label="Last Name"
                            fullWidth
                            variant="standard"
                            value={userAccount.lastName ?? ""}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setUserAccount({...userAccount, lastName: event.target.value });
                            }}
                            disabled={!userHasPermission("UserAccount_Detail_Edit", appUser) }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="fullName"
                            label="Full Name"
                            fullWidth
                            variant="standard"
                            value={userAccount.fullName ?? ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="status"
                            label="Status"
                            fullWidth
                            variant="standard"
                            value={userAccount.status ?? ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="createdBy"
                            label="Created By"
                            fullWidth
                            variant="standard"
                            value={userAccount.createdByName}
                            disabled
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="dateCreated"
                            label="Date Created"
                            fullWidth
                            variant="standard"
                            value={userAccount.dateCreated ? moment(userAccount.dateCreated).format("MM/DD/YYYY"): ""}
                            disabled
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="modifiedBy"
                            label="Modified By"
                            fullWidth
                            variant="standard"
                            value={userAccount.modifiedByName}
                            disabled
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="dateModified"
                            label="Date Modified"
                            fullWidth
                            variant="standard"
                            value={moment(userAccount.dateModified).format("MM/DD/YYYY")}
                            disabled
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TypeAheadMulti
                            value={userAccount.roles}
                            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setUserAccount({...userAccount, roles: newValue})}
                            searchCallback={(searchTerm?: string) => GetRoles(appUser?.token!)}
                            label="User Roles"
                            disabled={!userHasPermission("UserAccount_Detail_Edit", appUser) }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Switch 
                                            name="isInternalFlag" 
                                            checked={ userAccount.isInternalFlag ? true : false } 
                                            onChange={handleInternalFlagChange}
                                            disabled={!userHasPermission("UserAccount_Detail_Edit", appUser) }
                                        />
                                    }
                                    label="Is Internal User?"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Switch 
                                            name="isEmailVerified" 
                                            checked={ userAccount.emailVerified ? true : false } 
                                            disabled
                                        />
                                    }
                                    label="Is Email Verified?"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <UserAccountResetPasswordDialog userID={userAccount.userID} open={showResetPassWordDialog} onClose={() => setShowResetPassWordDialog(false)}/>
                    <UserAccountSetPasswordDialog auth0ID={userAccount.auth0ID ?? ''} open={showSetPassWordDialog} onClose={() => setShowSetPassWordDialog(false)}/>
                    <Dialog
                        open={userCreateErrorDialogOpen}
                        onClose={() => setUserCreateErrorDialogOpen(false)}
                    >
                        <DialogTitle >User Already Exists</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Alert severity="error">{ userCreateErrorDialogText }</Alert> 
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            { !userCreateErrorDialogResetPassword &&
                                <Button
                                    onClick={() => setUserCreateErrorDialogOpen(false)}
                                    autoFocus
                                >
                                    Ok
                                </Button>
                            }
                            { userCreateErrorDialogResetPassword &&
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="space-evenly"
                                >
                                    <Grid item xs>
                                        <Button
                                            fullWidth={true}
                                            onClick={() => {
                                                    setUserCreateErrorDialogOpen(false);    
                                                    createExistingAuth0User(true);
                                                }
                                            }
                                        >
                                            Create and Reset Password
                                        </Button>
                                    </Grid>
                                    <Grid item xs>
                                        <Button
                                            fullWidth={true}
                                            onClick={() => {
                                                    setUserCreateErrorDialogOpen(false);    
                                                    createExistingAuth0User(false);
                                                }
                                            }
                                        >
                                            Create Without Password Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs>
                                        <Button
                                            fullWidth={true}
                                            onClick={() => setUserCreateErrorDialogOpen(false)}
                                            autoFocus
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={errorDialogOpen}
                        onClose={() => setErrorDialogOpen(false)}
                    >
                        <DialogTitle >Oops...</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Alert severity="error">There was an issue {userIDNumber === 0 ? 'creating':'updating'} this account. Please contact a system administrator for help or try again.</Alert> 
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setErrorDialogOpen(false)} autoFocus>Ok</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </form>
        </BaseLayout>
    );
}

export default UserAccountDetail;