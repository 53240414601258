import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import { isEmpty } from "lodash";
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { EmptyTableTable } from "./EmptyTable.component";

const headerStyling = {
    background: "#1e22aa",
    color: "#ffffff",
    fontWeight: "700",
    padding: "5px 20px",
};

export const SoftwareApplicationsTable = (item: any) => {

    if (isEmpty(item.data)) {
        return (
            <EmptyTableTable />
        )
    }

    return (
        <TableWrapper>
            <TableContainer>
                <StyledTable sx={{ width: "100%" }}>
                    <TableHead>
                        <TableRow>                        
                            {item.data.headerData.map((row: any, index: number) => (
                                <TableCell align="center" sx={headerStyling} key={index}>
                                    {row.text}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>                      
                        {item.data.rowData.map((row: any, index: number) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell align="left">{row.col1}</TableCell>
                                <TableCell align="left">{row.col2}</TableCell>
                                <TableCell align="left">{row.col3}</TableCell>
                                <TableCell align="left">{row.col4}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};
