import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import { isEmpty } from "lodash";
import { EmptyTableTable } from "./EmptyTable.component";

const headerStyling = {
    background: "#1e22aa",
    color: "#ffffff",
    fontWeight: "700",
    padding: "5px 20px",
    minWidth: "150px"
};

const applyClassByFlag = (params: any) => {
    if (params.color_legend3 === 1) {
        return "bg-red";
    }

    if (params.color_legend2 === 1) {
        return "bg-lightblue";
    }

    if (params.color_legend1 === 1) {
        return "bg-pink";
    }

    return "" as string;
};

export const OpportunitiesTable = (item: any) => {

    if (isEmpty(item.data)) {
        return (
            <EmptyTableTable />
        )
    }

    return (
        <TableWrapper>
            <TableContainer sx={{mb:4}}>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            {item.data.headerData.map((row:any, index: number) => (
                                <TableCell
                                    key={`opportunitiesHeader-${index}`}
                                    align="center"
                                    sx={index === 0 ? {...headerStyling, minWidth: "50px"} : headerStyling }
                                >
                                    {row.text}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.data.rowData.map((row: any, index:number) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell align="center" className={applyClassByFlag(row)}>{row.col1}</TableCell>
                                {row.hasOwnProperty('col2') &&
                                <TableCell align="left" className={applyClassByFlag(row)}>{row.col2}</TableCell>                            
                                }
                                {row.hasOwnProperty('col3') &&
                                <TableCell align="center" className={applyClassByFlag(row)}>{row.col3}</TableCell>                            
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};
