import { Location } from "react-router-dom";
import moment, { Moment } from "moment";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models";
import { SearchRequestBase } from "../../../../models/SearchRequestBase.model";
import NumericDropDownModel from "../../../../models/NumericDropDown.model";
import { SearchResponseBase } from "../../../../models/SearchResponseBase.model";

export interface AchievementListSearchRequest extends SearchRequestBase {
    maturityModel: NumericDropDownModel[],
    stages: string[],
    countries: NumericDropDownModel[],
    geographicRegions: NumericDropDownModel[],
    publishedDate?: DateRange<Moment>,
    regions: NumericDropDownModel[]
}

export interface AchievementListResponse extends SearchResponseBase {
    id: number,
    facilityName: string,
    healthSystem: string,
    geographicalRegion: string,
    country: string,
    facilityTypeName: string,
    modelName: string,
    score: number
}

export function CreateDefaultAchievementListSearchRequest(location: Location|undefined):AchievementListSearchRequest{
    if(location && location.state?.componentState){
        return location.state.componentState;
    }

    return {
        maturityModel: [],
        stages: [],
        countries: [],
        geographicRegions: [],
        page: 0,
        pageSize: 100,
        sortColumn: "facilityName",
        sortDirection: "asc",
        publishedDate: [moment().add(-3, "y"), null],
        regions:[]
    };
}