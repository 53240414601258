import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';

const headerStyling = {
    background: "#1e22aa",
    color: "#ffffff",
    fontWeight: "700",
    padding: "5px 20px",
};

export const EmptyTableTable = (item: any) => {

    return (
        <TableWrapper>
            <TableContainer>
                <StyledTable sx={{ width: "100%" }}>
                    <TableHead>
                        <TableRow> 
                                <TableCell align="center" sx={headerStyling}>
                                        &nbsp;
                                </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                            <TableRow>
                                <TableCell align="center">
                                    <DoDisturbAltIcon sx={{ color: "grey", fontSize: '25px', opacity: ".5" }}/>
                                </TableCell>    
                            </TableRow>
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};
