import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import { isEmpty } from "lodash";
import { EmptyTableTable } from "./EmptyTable.component";

const applyDynamicClass = (row: any) => {
    if (row.id > 7) {
        return "bg-blue";
    }

    if (row.hgFailed === 1) {
        return "bg-red";
    }

    if (row.col2 > 69.5) {
        return "bg-green";
    }

    return "bg-yellow" as string;
}

const renderCell = (row: any, rowName: string) => {
    if (row.id !== 9 && row[rowName] !== "N/A") {
        return row[rowName].toLocaleString() + "%";
    }

    return row[rowName];
}

export const AssessmentOverviewTable = (item: any) => {

    if (isEmpty(item.data)) {
            return (
                <EmptyTableTable />
            )
    }

    return (
        <TableWrapper>
            <TableContainer>
                <StyledTable>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                        {item.data.rowData.map((row: any, index: number) => (
                            <TableRow
                                key={`assessmentOverview-${index}`}
                            >
                                <TableCell align="center" sx={{minWidth: "160px"}}>{row.col1}</TableCell>
                                <TableCell align="center" sx={{minWidth: "80px"}} className={applyDynamicClass(row)}>{renderCell(row, "col2")}</TableCell>
                                <TableCell align="center">{row.col3}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};
