import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import { isEmpty } from "lodash";
import { EmptyTableTable } from "./EmptyTable.component";

const applyClassFocusArea = (row:any, rowName: string, row_hg: number) => {
    if (isNaN(row[rowName]) && row.id > 2) {
        return "" as string;
    }

    if (row.id < 3) {
        return "bg-blue";
    }

    if (rowName === "col2" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col3" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col4" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col5" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col6" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col7" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col8" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col9" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (row[rowName] > 69.5) {
        return "bg-green";
    }

    return "bg-yellow" as string;
}

const renderCell = (row: any, rowName: string) => {
    if (row.id !== 1 && !isNaN(row[rowName])) {
        return row[rowName].toLocaleString() + "%";
    }
    
    return row[rowName];
}


export const FocusAreaTable = (item: any) => {
    
    if (isEmpty(item.data)) {
        return (
            <EmptyTableTable />
        )
    }

    return (
        <TableWrapper>
            <TableContainer>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" />
                            {item.data.headerData.map((row:any, index: number) => (
                                <TableCell key={`focusAreaHeader-${index}`} align="center">{row.text}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.data.rowData.map((row: any, index: number) => (
                            <TableRow
                                key={`focusAreaRow-${index}`}
                            >
                                {row.hasOwnProperty('col1') &&
                                <TableCell align="center">{row.col1}</TableCell>                            
                                }
                                {row.hasOwnProperty('col2') &&
                                <TableCell align="center" className={applyClassFocusArea(row, "col2", row.col2_hg)}>{renderCell(row, "col2")}</TableCell>                            
                                }
                                {row.hasOwnProperty('col3') &&
                                <TableCell align="center" className={applyClassFocusArea(row, "col3", row.col3_hg)}>{renderCell(row, "col3")}</TableCell>                            
                                }
                                {row.hasOwnProperty('col4') &&
                                <TableCell align="center" className={applyClassFocusArea(row, "col4",  row.col4_hg)}>{renderCell(row, "col4")}</TableCell>                            
                                }
                                {row.hasOwnProperty('col5') &&
                                <TableCell align="center" className={applyClassFocusArea(row, "col5",  row.col5_hg)}>{renderCell(row, "col5")}</TableCell>                            
                                }
                                {row.hasOwnProperty('col6') &&
                                <TableCell align="center" className={applyClassFocusArea(row, "col6",  row.col6_hg)}>{renderCell(row, "col6")}</TableCell>
                                }
                                {row.hasOwnProperty('col7') &&
                                <TableCell align="center" className={applyClassFocusArea(row, "col7",  row.col7_hg)}>{renderCell(row, "col7")}</TableCell>
                                }
                                {row.hasOwnProperty('col8') &&
                                <TableCell align="center" className={applyClassFocusArea(row, "col8",  row.col8_hg)}>{renderCell(row, "col8")}</TableCell>
                                }
                                {row.hasOwnProperty('col9') &&
                                <TableCell align="center" className={applyClassFocusArea(row, "col9",  row.col9_hg)}>{renderCell(row, "col9")}</TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};
