import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";
import { isEmpty } from "lodash";
import { EmptyTableTable } from "./EmptyTable.component";

const headerStyling = {
    background: "#1e22aa",
    color: "#ffffff",
    fontWeight: "700",
    padding: "5px 20px",
};

type ObjectLiteralType = {
    [int: number]: string
}

function applyDynamicClass(id: number): ObjectLiteralType {
    return {
        1: 'bg-green',
        2: 'bg-yellow',
        3: 'bg-red',
    }[id]!;
}

export const ColorLegendTable = (item: any) => {

    if (isEmpty(item.data)) {
        return (
            <EmptyTableTable />
        )
    }

    return (
        <TableWrapper >
            <TableContainer sx={{marginLeft: "20px", minWidth:"220px"}}>
                <StyledTable sx={{}}>
                    <TableHead>
                        <TableRow>
                            {item.data.headerData.map((row: any, index: number) => (
                                <TableCell align="center" sx={headerStyling} key={`colorLegendHeader-${index}`}>
                                    {row.text}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.data.rowData.map((row: any, index: number) => (
                            <TableRow
                                key={`colorLegendRow-${index}`}
                            >
                                <TableCell className={applyDynamicClass(row.id) as string} align="center">{row.col1}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};
