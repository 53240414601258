import { ApiBaseRoute } from "../api_helper";

export const GetRegionTypeAheadSearch = ( searchTerm?: string, includedRegionIDs?: number[]): Promise<Response> => {
    return fetch(`${ApiBaseRoute}regions/globalregiontypeaheadSearch`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            searchTerm: searchTerm ?? '',
            includedRegions: includedRegionIDs ?? []
        })
    });
}