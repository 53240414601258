import { ApiBaseRoute } from "../../api_helper";
import { UserDetailModel } from "./UserAccount.models";

export const GetUserAccount = (userID: number, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "Users/Detail?userID=" + userID, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const GetUserProfile = (token?:string):Promise<Response> => {
    return fetch(ApiBaseRoute + "Users/UserProfile", {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const GetRoles = (token: string):Promise<Response> => {
    return fetch(ApiBaseRoute + "UserRoles", {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const FileUser = (token: string, userDetail: UserDetailModel):Promise<Response> => {
    return fetch(ApiBaseRoute + "Users/FileUser",{
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(userDetail)
    });
};

export const CheckUser = (token: string, userDetail: UserDetailModel):Promise<Response> => {
    return fetch(ApiBaseRoute + "Users/CheckUser",{
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(userDetail)
    });
};

export const ResetPassword = (userID:number, token: string|undefined):Promise<Response> => {
    return fetch(`${ApiBaseRoute}Users/ResetPassword?userID=${userID}`,{
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
    });
};

export const SetUserPassword = (auth0ID: string, token: string|undefined, password: string):Promise<Response> => {
    return fetch(`${ApiBaseRoute}Users/OverWritePassword`,{
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({auth0ID, password})
    });
};

export const IsPasswordValid = (password:string|undefined):boolean => {
    let passed = 0;
    if(password && password!.match(/(?=.*\d)/)){
        passed += 1;
    }
    if(password && password!.match(/(?=.*[a-z])/)){
        passed += 1;
    }
    if(password && password!.match(/(?=.*[A-Z])/)){
        passed += 1;
    }
    if(password && password!.match(/(?=.*\W)/)){
        passed += 1;
    }
    return !!password && password!.length > 7 && passed > 2;
};
